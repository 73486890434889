

import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Pagination, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import search from '../../assets/search.svg';
import exportIcon from '../../assets/export-icon.svg';
import productRoute from '../../services/productRoute';
import { useAppContext } from '../../AppContext';
import edit from '../../assets/edit.svg'
import {useLocation, useNavigate} from 'react-router-dom'
import deleteIcon from '../../assets/delete.svg'
import { CSVLink } from 'react-csv';
// import {ExportToExcel} from 'react-export-table-to-excel';
import { useDownloadExcel } from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import LogoutModal from '../../components/Modal/Logout';
import DeleteProductModal from '../../components/Modal/DeleteProductModal';


const AdminProducts = () => {
    const { showToast,showDeleteModal, setShowDeleteModal,productState,setProductState } = useAppContext();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [isStockOpen, setIsStockOpen] = useState(false);
    const [isSeasonOpen, setIsSeasonOpen] = useState(false);
    const [isExportOpen, setIsExportOpen] = useState(false);
    const [searchProduct, setSearchProduct] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [globalProducts, setGlobalProducts] = useState([]);
    const { getProducts, getGlobalProducts, getProductCategory, filterByCategory, filterBySeason,filterByStockLevel, filterProducts } = productRoute();
    const [exportFormat, setExportFormat] = useState(null); 
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [categories, setCategories] = useState([]);
    const [activeFilters, setActiveFilters] = useState([]); 
  const [selectedFilters, setSelectedFilters] = useState({
    category: null,
    season: null,
    stock: null,
  }); 
    const [currentProduct, setCurrentProduct] = useState(null);
    const tableRef = useRef(null);
    const dropdownRef1 = useRef(null);
    const dropdownRef2 = useRef(null);
    const dropdownRef3 = useRef(null);
    const dropdownRef4 = useRef(null);
    const dropdownRef5 = useRef(null);
    const dropdownRef6= useRef(null);
    const location = useLocation()

    useEffect(() => {
      // Restore the current page if available in location state
      if (location.state?.currentPage) {
          setPagination(prevState => ({
              ...prevState,
              currentPage: location.state.currentPage
          }));
         
      }
  }, [location?.state]);
   
  const [pagination, setPagination] = useState({
    currentPage: location.state?.currentPage || 1,
      itemsPerPage: 20,
      totalPages: 0,
      totalProducts: 0,
  });
  


      useEffect(() => {
        // Define a debounced version of the getProducts function
        const debouncedGetProducts = debounce((searchProduct) => {
            getProducts(
                setLoading,
                showToast,
                setProducts,
                pagination,
                setPagination,
                searchProduct,
            );
        }, 500);
    
        // Call the debounced getProducts function when the search product changes and it's not empty
        if (searchProduct !== '') {
          setSearchPerformed(true)
           // If the user types quickly or navigates away, call getProducts immediately
    if (loading) {
      debouncedGetProducts.flush();  // Immediately execute the debounced function if user navigates
    }

            debouncedGetProducts(searchProduct);
        } else {
            // If the search product is empty, call getProducts immediately
            getProducts(
                setLoading,
                showToast,
                setProducts,
                pagination,
                setPagination,
                ''
            );
        }
    
        // Cleanup function to cancel the debounced function
        return () => {
            debouncedGetProducts.cancel();
        };
    }, [pagination?.currentPage, searchProduct, location.state?.currentPage]);

      useEffect(() => {
    

        getProductCategory(
          setLoading, showToast, setCategories
         
        )
    
        
      }, []); 



      useEffect(() => {

        getGlobalProducts(
          setLoading, showToast, setGlobalProducts, 
         
        )
          
        }, []);

        useEffect(() => {
          function handleClickOutside(event) {
            if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
              setIsOpen(false);
            }
            if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
              setIsCategoryOpen(false);
            }
            if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
              setIsExportOpen(false);
            }
            if (dropdownRef4.current && !dropdownRef4.current.contains(event.target)) {
              setIsSeasonOpen(false);
            }
            if (dropdownRef5.current && !dropdownRef5.current.contains(event.target)) {
              setIsStockOpen(false);
            }
          
          }
      
          function handleScroll() {
        
            setIsExportOpen(false);
          }
      
          // Add event listeners
          document.addEventListener("mousedown", handleClickOutside);
          window.addEventListener("scroll", handleScroll, true);
      
          return () => {
            // Clean up
            document.removeEventListener("mousedown", handleClickOutside);
            window.removeEventListener("scroll", handleScroll, true);
          };
        }, []);

        useEffect(() => {
          const updatedActiveFilters = [];
        
          if (selectedFilters.category) {
            const selectedCategory = categories.find(
              (category) => category._id === selectedFilters.category
            );
            updatedActiveFilters.push({
              type: 'Category',
              value: selectedCategory ? selectedCategory.categoryName : 'N/A',
            });
          }
        
          if (selectedFilters.season) {
            updatedActiveFilters.push({
              type: 'Season',
              value: selectedFilters.season,
            });
          }
        
          if (selectedFilters.stock) {
            updatedActiveFilters.push({
              type: 'Stock',
              value: selectedFilters.stock,
            });
          }
        
          setActiveFilters(updatedActiveFilters);
        }, [selectedFilters, categories]);
        
        const applyFilters = () => {
          const categoryId = selectedFilters.category;
          let stockLevel;
          if (selectedFilters.stock === 'In-stock') {
            stockLevel = 'in_stock';
          } else if (selectedFilters.stock === 'Low-stock') {
            stockLevel = 'low_stock';
          } else if (selectedFilters.stock === 'Out-of-stock') {
            stockLevel = 'out_of_stock';
          }
      
          let seasonStatus;
          if (selectedFilters.season === 'In Season') {
            seasonStatus = true;
          } else if (selectedFilters.season === 'Off Season') {
            seasonStatus = false;
          }
   
          filterProducts(setLoading, setProducts, showToast, setPagination,{ categoryId, seasonStatus, stockLevel });
        };


         
        useEffect(() => {
          if (
            selectedFilters.season || 
            selectedFilters.stock || 
            selectedFilters.category
          ) {
            applyFilters(); // Apply filters whenever selectedFilters changes
          }
          else if (  !selectedFilters.season && 
            !selectedFilters.stock && 
            !selectedFilters.category){
              getProducts(
                setLoading,
                showToast,
                setProducts,
                pagination,
                setPagination,
                searchProduct,
            );
            
          }
        }, [selectedFilters]);


        const handleCSVExport = () => {
          const csvData = prepareCSVData();
          if (csvData) {
            return (
              <CSVLink data={csvData.data} headers={csvData.headers} filename="product-list.csv" className='csv-link'>
                As CSV
              </CSVLink>
            );
          } else {
            console.error("CSV data is not available.");
          }
        };
        
        const prepareCSVData = () => {
          if (globalProducts.length === 0) {
            console.warn("No products available for CSV export.");
            return null;
          }
          
          const headers = [
            { label: 'Name', key: 'productName' },
            { label: 'Category', key: 'categoryName' },
            { label: 'Selling Price', key: 'price' },
            // Add more headers as needed
          ];
          
          const data = globalProducts.map(product => ({
            productName: product.productName,
            categoryName: product?.category?.categoryName, // Corrected mapping
            price: product?.price,
            // Map other product properties
          }));
          
          return { headers, data };
        };
        
        

  const toggleDropdown = () => setIsOpen(!isOpen);
  const toggleDropdown2 = () => setIsOpen2(!isOpen2);
  const toggleCategoryDropdown = () => setIsCategoryOpen(!isCategoryOpen);
  const toggleSeasonDropdown = () => setIsSeasonOpen(!isSeasonOpen);
  const toggleStockDropdown = () => setIsStockOpen(!isStockOpen);
  const toggleExportDropdown = () => setIsExportOpen(!isExportOpen);
  const handleFocus = () => {
    setIsFocused(true)
  };

  const handleProductClick = (productId) => {
 
    navigate(`/admin/products/${productId}`, { 
        state: { 
            currentPage: pagination.currentPage,
            previousPath: location.pathname 
        } 
    });
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  const handlePageChange = (page) => {
    setPagination(prevState => ({
      ...prevState,
      currentPage: page
    }));
  };
  const handleToggleSelectAll = () => {
    if (selectedProductIds.length === products?.length) {
        setSelectedProductIds([]); // Deselect all
    } else {
        setSelectedProductIds(products.map(product => product.id)); // Select all
    }
  };

  const handleCheckboxChange = (productId, isChecked, event) => {
    
    
    if (isChecked) {
        setSelectedProductIds(prevIds => [...prevIds, productId]);
    } else {
        setSelectedProductIds(prevIds => prevIds.filter(id => id !== productId));
    }
};
  

const getStatusStyle = (status) => {
  // Define colors for each stock status
  const colorMap = {
      'in_stock': '#08875D',
      'out_of_stock': '#CE371C',
      'low_stock': '#F2994A'
  };

  // Get color based on status, default to out-of-stock color if not found
  const color = colorMap[status] || colorMap['out_of_stock'];

  // Return common style with specific color
  return {
      color: color,
      border: `1px solid ${color}`,
      padding: '6px 16px',
      borderRadius: '12px'
  };
};

const capitalizeWords = (string) => {
  return string.replace(/_/g, '-') // Replace underscores with spaces
              .toLowerCase() // Convert all to lower case
              .replace(/\b(\w)/g, s => s.toUpperCase()); // Capitalize first letters of each word
};

// Adjusted function to handle different product stock statuses correctly
const determineStatus = (product) => {
  let statusText = 'Level Unknown';
  let statusStyle;

  if (product && product.stockLevel) {
      statusText = capitalizeWords(product.stockLevel);
      statusStyle = getStatusStyle(product.stockLevel);
  } else {
      // Fallback styles if product or stockLevel is undefined
      statusStyle = { color: '#CE371C', border: '1px solid #CE371C', padding: '6px 16px', borderRadius: '12px' };
  }

  return <span style={statusStyle}>{statusText}</span>;
};

const handleStateClick = (pState) => {
  setProductState(pState);
  if(pState === 'Products'){
    navigate('/admin/products')
  }else   if(pState === 'Categories'){
    navigate('/admin/categories')
  }

  };
      
const handleAdd = ()=> {
  navigate('/admin/products/add')
}

const handleBulkAction = (action) => {
  const selectedProducts = globalProducts.filter(product => selectedProductIds.includes(product.id));
  
  const productsToAction = selectedProductIds.length > 0 ? { products: selectedProducts } : { fetchAll: true };

  // Navigate to the bulk action route and pass necessary product details or flag to fetch all: 
  navigate(`/admin/products/bulk/${action}`, {
    state: { productsToAction,
      currentPage: pagination.currentPage, 
      previousPath: location.pathname }
  });
};

const handleCategoryClick = (categoryId) => {
  filterByCategory(categoryId, setLoading, setProducts, showToast,setPagination);
  
};

const handleSelectStockStatus = (stock) => {
  let stockLevel;
  if(stock === 'In-stock'){
    stockLevel='in_stock'
  }else if(stock === 'Low-stock'){
    stockLevel='low_stock'
  }else if(stock === 'Out-of-stock'){
    stockLevel='out_of_stock'
  }
  filterByStockLevel(stockLevel, setLoading, setProducts, showToast,setPagination);
  
};

const handleSeasonStatusFilter = (status) => {
  let seasonStatus;
  if(status === 'In Season'){
    seasonStatus = true
  }else if(status === 'Off Season'){
    seasonStatus = false
  }
  filterBySeason(seasonStatus, setLoading, setProducts, showToast, setPagination);
  
};



const handleFilterChange = (type, value) => {
  setSelectedFilters((prevFilters) => ({
    ...prevFilters,
    [type]: prevFilters[type] === value ? null : value, 
  }));
};

const handleRemoveFilter = (type) => {
  setSelectedFilters((prevFilters) => ({
    ...prevFilters,
    [type.toLowerCase()]: null,
  }));
};

const clearFilters = () => {
  setSelectedFilters({
    category: null,
    season: null,
    stock: null,
  });
 
  setActiveFilters([]); 
  getProducts(
    setLoading,
    showToast,
    setProducts,
    pagination,
    setPagination
)
};

const { onDownload } = useDownloadExcel({
  currentTableRef: tableRef.current,
  filename: 'Products-List',
  sheet: 'Products'
})



const handlePDFExport = () => {
  const input = document.getElementById('product-table');
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 190;
    const imgHeight = canvas.height * imgWidth / canvas.width;
    pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
    pdf.save('product-list.pdf');
  });
};

const getAllProducts = ()=> {
  getProducts(
    setLoading,
    showToast,
    setProducts,
    pagination,
    setPagination
)
}

  return (
    <section className=''>
  <div className='d-flex justify-content-between align-items-center header-admin  py-5 pe-5 ps-4'>
        <h3 style={{color:'#344054'}}>Products</h3>
     <div className='d-flex gap-3'>
     
   <Dropdown show={isExportOpen}  ref={dropdownRef3}>
        <Button  variant='secondary' id="dropdown-basic"   style={{fontWeight:'500', color:'#333333'}} onClick={toggleExportDropdown} className='d-flex align-items-center gap-2 '>
        Export<img src={exportIcon} />
        </Button>
        <Dropdown.Menu>
          <Dropdown.Item onClick={onDownload}>As Excel</Dropdown.Item>
          <DropdownDivider />
          <Dropdown.Item onClick={handlePDFExport}>As PDF</Dropdown.Item>
          <DropdownDivider />
       
          {handleCSVExport()}
      
      
         
        </Dropdown.Menu>
      </Dropdown>
     {selectedProductIds.length === 0 && (
      <Dropdown show={isOpen} ref={dropdownRef1}>
        <Button id="dropdown-basic" variant='secondary'  onClick={toggleDropdown} className='d-flex align-items-center gap-2 p-2'>
        Bulk Actions <FontAwesomeIcon icon={faChevronDown} />
        </Button>

        <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleBulkAction('price')} >Price Update</Dropdown.Item>
        <DropdownDivider/>
        <Dropdown.Item onClick={() => handleBulkAction('stock')}>Stock Update</Dropdown.Item>
    
        </Dropdown.Menu>
      </Dropdown>)}

      <Button className='d-flex align-items-center gap-3 p-2' onClick={handleAdd}>
      <FontAwesomeIcon icon={faPlus} />
       Add Product 
      </Button>

      
     </div>
    </div>
    <div className='d-flex gap-5 mb-3 ps-4' style={{borderBottom:'1px solid #EDEDED'}}>
    {['Products', 'Categories'].map((pState, index) => (
          <p
            key={index}
            className={`order-item pb-2 mb-0 me-4 ${productState === pState ? 'active' : ''}`}
            onClick={() => handleStateClick(pState)}
            style={{ cursor: 'pointer', marginBottom: '5px' }}
          >
            {pState}
          </p>
        ))}
      </div>

  <div className='px-3'>
    <div className='d-flex align-items-center justify-content-between py-4'>
      <div className='d-flex align-items-center gap-5'>
       
       
  {selectedProductIds.length > 0 ? (
     <Form.Check
     checked={selectedProductIds.length === products?.length}
     label={`${selectedProductIds.length} selected`}
     className='selected-check'
     onClick={handleToggleSelectAll}/> 
  ): <p style={{color:'#1D2939', fontWeight:'500'}} className='my-auto'> {pagination?.totalProducts !== 0 ? pagination?.totalProducts : products?.length} Products</p> }

{selectedProductIds.length > 0 && (
     <Dropdown show={isOpen}  ref={dropdownRef1}>
     <Button variant='secondary' id="dropdown-basic"  onClick={toggleDropdown} className='d-flex align-items-center gap-4 p-2'  style={{fontWeight:'500', color:'#333333', fontSize:'12px'}}>
     Bulk Actions <FontAwesomeIcon icon={faChevronDown} />
     </Button>

     <Dropdown.Menu>
     <Dropdown.Item onClick={() => handleBulkAction('price')} >Price Update</Dropdown.Item>
     <DropdownDivider/>
     <Dropdown.Item onClick={() => handleBulkAction('stock')}>Stock Update</Dropdown.Item>
   
     </Dropdown.Menu>
   </Dropdown>
  ) }
        <div className='d-flex align-items-center position-relative search-bar py-1 px-1' style={{border:'1px solid #D9D9D9', fontWeight:'500'}}>
        {!isFocused && searchProduct === '' && (<>
        <img src={search} alt='search' style={{top: '24%', left: '1.5%'}} className='position-absolute'/>
    
         </>)}
         {searchProduct !== '' && <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => setSearchProduct('')}></i>}
        <Form.Control
        style={{border: 'none', backgroundColor: 'transparent'}}
        placeholder='Search by product name'
        className='w-100'
        value={searchProduct}
        onFocus={handleFocus}
        onBlur={handleBlur} 
        onChange={(e)=>setSearchProduct(e.target.value)}
        />
      </div>
      </div>
       
        <div className='d-flex gap-2 align-items-center'>
          
            <Dropdown show={isCategoryOpen}  ref={dropdownRef2}>
 

<Button variant='secondary' id="dropdown-basic"  onClick={toggleCategoryDropdown}  className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#333333'}}>
Categories &nbsp; &nbsp;{isCategoryOpen? <FontAwesomeIcon icon={faChevronUp} />: <FontAwesomeIcon icon={faChevronDown} />}
     </Button>
     <Dropdown.Menu className='px-2' style={{width:'200px'}}>
  
          {isCategoryOpen && categories.map((category, index) => (
            // <Dropdown.Item key={index} onClick={() => {toggleCategoryDropdown(); toggleDropdown2(); handleCategoryClick(category._id)}}>{category?.categoryName}</Dropdown.Item>

            <Form.Check
  key={category?._id}
  type="checkbox"
  label={category?.categoryName}
  checked={selectedFilters.category === category?._id}
  onChange={() => {handleFilterChange('category', category?._id);toggleCategoryDropdown()}}
/>

          ))}
   
   </Dropdown.Menu>
   
   </Dropdown>

   <Dropdown show={isSeasonOpen}  ref={dropdownRef4}>
 

 <Button variant='secondary' id="dropdown-basic"  onClick={toggleSeasonDropdown} className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#333333'}}>
 Season Status &nbsp; {isSeasonOpen? <FontAwesomeIcon icon={faChevronUp} />: <FontAwesomeIcon icon={faChevronDown} />}
      </Button>
      <Dropdown.Menu className='px-2'>
   
      {isSeasonOpen &&  [ 'In Season', 'Off Season'].map((status, index) => (
          //    <Dropdown.Item
          //    key={index}
             
          //    onClick={() => {handleSeasonStatusFilter(status); toggleDropdown2()}}
          //  > {status}
          //  </Dropdown.Item>
           <Form.Check
                                 key={status}
                                 type="checkbox"
                                 label={status}
                                 checked={selectedFilters.season === status}
                                 onChange={() => { handleFilterChange('season', status);toggleSeasonDropdown()}}
                               />
         ))}
    
    </Dropdown.Menu>
    
    </Dropdown>

    <Dropdown show={isStockOpen}  ref={dropdownRef5}>
 

 <Button variant='secondary' id="dropdown-basic" onClick={toggleStockDropdown}  className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#333333'}}>
 Stock Level {isStockOpen? <FontAwesomeIcon icon={faChevronUp} />: <FontAwesomeIcon icon={faChevronDown} />}
      </Button>
      <Dropdown.Menu className='px-2'>
   
      {isStockOpen && [ 'In-stock', 'Low-stock', 'Out-of-stock'].map((level, index) => (
                          <Form.Check
                            key={level}
                            type="checkbox"
                            label={level}
                            checked={selectedFilters.stock === level}
                            onChange={() => {handleFilterChange('stock', level);toggleStockDropdown()}}
                          />
    
  // <Dropdown.Item
  //   key={index}
    
  //   onClick={() => {handleSelectStockStatus(stock); toggleDropdown2()}}
  // > {stock}
  // </Dropdown.Item>
))}
    
    </Dropdown.Menu>
    
    </Dropdown>
    {/* <Dropdown.Item onClick={()=> {getAllProducts(); toggleDropdown2()}} className='d-flex justify-content-between'>All</Dropdown.Item> */}


            
        </div>
    </div>
    <div className='my-2 d-flex '>
      
    {activeFilters.length > 0 && (
            <div className="d-flex gap-2 align-items-center">
              <h6 style={{fontWeight:'500'}} className='my-2'>Filter by:</h6>
              {activeFilters.map((filter, index) => (
               
                  
                  <Button key={index} 
                 
                   classNameame="" style={{borderRadius:'20px', fontSize:'14px', border:'1px solid #4F4F4F4D',backgroundColor:'#EAEAEA33',color:'#333333', fontWeight:'500'}}
                  >
                   {filter.value} &nbsp; <span className='px-1 py-0 align-items-center' style={{borderRadius:'50%', border:"1px solid #333333"}}  onClick={() => handleRemoveFilter(filter.type)}>x</span>
                  </Button>
                
              ))}
              <Button
                   
                    className="apply-filters-button"
                    onClick={clearFilters}
                    disabled={activeFilters.length === 0} style={{fontSize:'14px',backgroundColor:'#4F4F4F', color:'#FFFFFF'}}
                  >
                    Reset Filter
                  </Button>
            </div>
          )}
    </div>
    {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):products?.length > 0 ? <>
    <Table hover className='my-3' id="product-table" ref={tableRef} >
       <thead>
            <tr style={{fontWeight:'600'}} className='mb-2'>
            <th style={{fontWeight:'600'}} className='pb-3'></th>
                <th style={{fontWeight:'600'}} className='pb-3'>Name</th>
                <th style={{fontWeight:'600'}} className='pb-3'>Categories</th>
                <th style={{fontWeight:'600'}} className='pb-3'>Selling Price</th>
                <th style={{fontWeight:'600'}} className='pb-3'>Discount Price</th>
                <th style={{fontWeight:'600'}} className='pb-3'>Stock Level</th>
                <th style={{fontWeight:'600'}} className='pb-3'></th>
                <th style={{fontWeight:'600'}} className='pb-3'></th>
                
            </tr>
        </thead>
            <tbody className='py-2'>
            {products?.map((product) => (
              <tr style={{fontSize:'14px', fontWeight:'400',color:'#333333', paddingTop: '15px', paddingBottom: '15px' }} key={product?.id}  onClick={() => handleProductClick(product.id)}>
                <td style={{paddingTop: '15px', paddingBottom: '15px' }}> <div onClick={(e) => e.stopPropagation()}>
            <Form.Check
                checked={selectedProductIds.includes(product.id)}
                onChange={(e) => {
                    handleCheckboxChange(product.id, e.target.checked);
                }}
            />
        </div>
</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px', color:'#194298', cursor:'pointer', textDecoration:'underline' }} onClick={() =>    navigate(`/admin/products/${product?.id}`)}>{product?.productName}</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}>  {product.categories?.map((category) => category.categoryName).join(', ')}</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}>₦{product?.price?.toLocaleString()}</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{product?.discount ? product?.discount.toLocaleString() : '-'}</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{determineStatus(product)}</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}><div onClick={(e) => e.stopPropagation()}className='d-flex'><img src={edit}   onClick={() =>
                    navigate(`/admin/products/${product?.id}`, {
                      state: { isEditing: true },
                    })
                  } style={{cursor:'pointer'}}/> &nbsp;&nbsp; <img src={deleteIcon} onClick={() => { setCurrentProduct(product);
    setShowDeleteModal(true);
}}/></div></td>
               <td style={{paddingTop: '15px', paddingBottom: '15px',cursor:'pointer', textDecoration:'underline', color:'#469B12'}}  onClick={() => handleProductClick(product.id)}>View</td>
              </tr>
            ))}
            </tbody>
    </Table> 
 
    {products.length > 0 && pagination.totalPages > 1 &&  (

<div className='d-flex justify-content-between align-items-center my-5 pt-5' style={{height:'50px'}}>
  <p style={{fontWeight:'500', color:'#919EAB'}}>Showing {products?.length} of {pagination.totalProducts} products</p>
  {pagination.totalPages > 1 && 
<Pagination className='custom-pagination d-flex gap-2' >
 
<button
  className='btn btn-secondary pb-0 px-2'
  disabled={pagination.currentPage === 1}
    onClick={() => handlePageChange(pagination.currentPage - 1)}
  style={{
    border: pagination.currentPage === 1 ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === 1 ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-left"></i></h6>
</button>
 {/* First Page */}
 <Pagination.Item onClick={() => handlePageChange(1)} active={pagination.currentPage === 1}>
    1
  </Pagination.Item>

  {/* Second Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(2)} active={pagination.currentPage === 2}>
      2
    </Pagination.Item>
  )}

  {/* Ellipsis after Second Page if necessary */}
  {pagination.currentPage > 3 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Current Page (if not first, second, last, or second to last page) */}
  {pagination.currentPage > 2 && pagination.currentPage < pagination.totalPages - 1 && (
    <Pagination.Item active>
      {pagination.currentPage}
    </Pagination.Item>
  )}

  {/* Ellipsis before Last Page if necessary */}
  {pagination.currentPage < pagination.totalPages - 2 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Second to Last Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(pagination.totalPages - 1)} active={pagination.currentPage === pagination.totalPages - 1}>
      {pagination.totalPages - 1}
    </Pagination.Item>
  )}

  {/* Last Page */}
  <Pagination.Item onClick={() => handlePageChange(pagination.totalPages)} active={pagination.currentPage === pagination.totalPages}>
    {pagination.totalPages}
  </Pagination.Item>


<button
  className='btn btn-secondary pb-0 px-2'
  disabled={pagination.currentPage === pagination.totalPages}
  onClick={() => handlePageChange(pagination.currentPage + 1)}
  style={{
    border: pagination.currentPage === pagination.totalPages ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === pagination.totalPages ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-right"></i></h6>
</button>
 
  </Pagination>}

</div>)}
</>
 
    : searchPerformed && products.length === 0 ?  <div className='fw-medium px-5' style={{padding:'40px'}}><h4 className='mb-2'>No products found</h4><Button onClick={() => setSearchProduct('')}>View all products</Button>
    </div>:
    <div className='fw-medium ' style={{padding:'40px'}}><h4>You have no products</h4><Button onClick={() => setSearchProduct('')}>View all products</Button></div>
   }

</div>
<DeleteProductModal item={currentProduct}
      itemType="product" actionType="delete"/>
  
    </section>
  
  )
}

export default AdminProducts;